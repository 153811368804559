import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import african from "../../../assets/img/banner/african.jpg";
import fertilizingPlants from "../../../assets/img/banner/fertilizing-plants.jpg";
import fertilizerLeaf from "../../../assets/img/banner/fertilizer-leaf.jpg";

import bann1 from "../../../assets/img/leatzmeat/banner/banner-1.jpg"
import bann2 from "../../../assets/img/leatzmeat/banner/banner-2.png"
import bann3 from "../../../assets/img/leatzmeat/banner/banner-3.png"

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";

const bannerImage = [
  { id: "1", bannerUrl: bann1, },
  { id: "2", bannerUrl: bann2, },
  { id: "3", bannerUrl: bann3, },
]

function Banner() {
  const [data, setData] = useState()
  // console.log('banner', data);

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/banner/public`)
      setData(res.data)
    } catch (error) {
      alert('Server Error BannerList')
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <section className="bannerSection">
        <Slider {...settings}>
          {data && data.map((item) => {
            return <Link to={item?.url} key={item?._id}>
              <div className="bannerItem">
                <div
                  className="bannerDetail"
                  style={{ backgroundImage: `url(${item?.image ? item?.image.url : fertilizingPlants})` }}
                >
                  {/* <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="bannerContent">
                          <div className="title">
                            <h6>find the boundaries. push through!</h6>
                            <h3>{item?.SliderTopHeading}</h3>
                            <div className="percentOff">
                              <h1 style={{ color: "transparent" }}>.</h1>
                              <div className="priceSection">
                                <span className="number">{item?.bottomText}</span>
                              </div>

                            </div>
                          </div>

                          <div className="shopNow">
                            <Link to="/products">shop now</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </Link>
          })}
        </Slider>
      </section>
    </>

    // <>
    //   <section className="bannerSection">
    //     <Slider {...settings}>
    //       {bannerImage && bannerImage.map((item) => {
    //         return <Link to={item?.url}>
    //           <div className="bannerItem">
    //             <div
    //               className="bannerDetail"
    //               style={{ backgroundImage: `url(${item?.bannerUrl})` }}
    //             >
    //               <div className="container">
    //                 <div className="row">
    //                   <div className="col-lg-12">
    //                     <div className="bannerContent">


    //                       {/* <div className="shopNow">
    //                         <Link to="/products">shop now</Link>
    //                       </div> */}
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </Link>
    //       })}



    //     </Slider>
    //   </section>
    // </>



  );
}

export default Banner;
