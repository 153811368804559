import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'

import brandLogo from "../../../assets/img/leatzmeat/download.png";
import { Link, useNavigate } from "react-router-dom";
import { BiCategory, BiPhoneCall } from "react-icons/bi";
import { AiOutlineUser, AiOutlineHeart, AiOutlineCloseCircle } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { BsClockHistory, BsHandbag } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { FaBars, FaHistory } from "react-icons/fa";
import "./SearchBox.css";
import { useRef } from "react";
import { useGetCartQuery, getProducts, setUpdatedProducts, setObjectVal, useGetWishListQuery, setCartCalc } from "../../../components/products/productSlice";
import axios from "axios";
import DeliveryLocation from "../../../components/deleveryLocation/DeleveryLocation";

import cat1 from "../../../assets/img/leatzmeat/category/Todays_Deal_1.webp";
import s1 from '../../../assets/img/leatzmeat/service/s1.png'
import s2 from '../../../assets/img/leatzmeat/service/s2.png'
import s3 from '../../../assets/img/leatzmeat/service/s3.png'
import s4 from '../../../assets/img/leatzmeat/service/s4.png'
import s5 from '../../../assets/img/leatzmeat/service/s5.png'
import s6 from '../../../assets/img/leatzmeat/service/s6.png'
import s7 from '../../../assets/img/leatzmeat/service/s7.png'
import s8 from '../../../assets/img/leatzmeat/service/s8.png'
import s9 from '../../../assets/img/leatzmeat/service/s9.png'
import s10 from '../../../assets/img/leatzmeat/service/s10.png'
import s11 from '../../../assets/img/leatzmeat/service/s11.png'
import s12 from '../../../assets/img/leatzmeat/service/s12.png'
import { useTranslation } from "react-i18next";

function SearchBox({ showMenu, val }) {
  const [modalShow, setModalShow] = useState(false);
  const userid = window.localStorage.getItem("user_id");
  const isLogin = window.localStorage.getItem('isLogin')
  const nums = window.localStorage.getItem('callNum')
  const [click, setClick] = useState(false)

  const { data, isSuccess, isFetching } = useGetCartQuery(userid)

  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const { data: value } = useGetWishListQuery(userid)
  const [trendSearch, setTrendSearch] = useState(null)
  const inputRef = useRef()
  // console.log(inputRef.current.value, 'inputRef---');
  const handleRef = useRef();
  const dispatch = useDispatch()

  const [valuewish, setValuewish] = useState()

  const getDatas = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/user/wishlist/${userid}`)
      setValuewish(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDatas()
  }, [])

  const [state, setState] = useState()

  const onSearch = (onSearch) => {
    // handleRef(onSearch)
    navigate(`/products/${onSearch}`);
    setShow(false)
  }

  const handleShow = (a) => {
    setShow(!show);
  }

  const { updatedProducts, products, cartLengh } = useSelector((item) => {
    return item.productList
  })

  useEffect(() => {
    // if (data) {
    //   console.log('searchboox', data);
    //   dispatch(getProducts(data?.cart?.products))
    //   dispatch(setObjectVal(data))
    //   if (isLogin === 'true') {
    //     dispatch(setUpdatedProducts(data.cart))
    //   }
    //   dispatch(setCartCalc(data))
    // }
  }, [data])

  const handleClick =async () => {
    let currentVal = inputRef.current.value.toLowerCase();
    const res =await axios.get(`https://onlineparttimejobs.in/api/product/search/${inputRef.current.value}`, { withCredentials: true })
    navigate(`/products/${currentVal}`);
    currentVal = ""
    setShow(false)
    getData()
  }

  const getData = async () => {
    const resData = await axios.get(`https://onlineparttimejobs.in/api/product/trendingSearches`, { withCredentials: true })
    setTrendSearch(resData?.data)
  }
  useEffect(() => {
    getData()
  }, [])


  useEffect(() => {
    // if (!handleRef.current) {
    //   return
    // }
    let handler = (e) => {
      if (show === true) {
        if (!handleRef?.current?.contains(e.target)) {
          setShow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return function () {

    }
  }, []);

  useEffect(() => {
    if (isLogin === 'true') {
      setState(data)
    } else {
      setState([])
    }
  }, [isFetching])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick()
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setModalShow(true)
  //   }, 2000)
  // }, [])

  const [catData, setCatData] = useState(null)

  const getDataa = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/category/filter`, { withCredentials: true })
    setCatData(res.data)
  }

  useEffect(() => {
    getDataa()
  }, [])

  const categories = {
    title: "Shop by categories",
    subTitle: "Freshest meats and much more!",
    categData: [
      { id: "1", imgUrl: s1, catTitle: "Today Deals" },
      { id: "2", imgUrl: s2, catTitle: "chiken" },
      { id: "3", imgUrl: s3, catTitle: "Fish & Seafood" },
      { id: "4", imgUrl: s4, catTitle: "Mutton" },
      { id: "6", imgUrl: s6, catTitle: "Parwans" },
      { id: "5", imgUrl: s5, catTitle: "Ready to Cooks" },
      { id: "7", imgUrl: s7, catTitle: " Eggs" },
      { id: "8", imgUrl: s8, catTitle: " Spread" },
      { id: "9", imgUrl: s9, catTitle: " Cold Cuts" },
      { id: "10", imgUrl: s10, catTitle: " Biryani & Kebab" },
      { id: "11", imgUrl: s11, catTitle: " Plant Based Meat" },
      { id: "12", imgUrl: s12, catTitle: "Meat Masala" },
    ]
  }

  const [serchTerm, setSerachTerm] = useState('')

  const { t } = useTranslation()

  return (
    <>
      <section className="searchSec">
        <div className="container">
          <div className="searchItem">
            <div className="logo">
              <Link to="/" onClick={()=>{setShow(false)}}>
                {/* <img src={val?.icon?.url ? val?.icon?.url : brandLogo} alt="logo" /> */}
                <img src={brandLogo} alt="" />
              </Link>
              <div className="bar">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <FaBars onClick={showMenu} />
                </button>
              </div>
            </div>
            <div className="select-delivery">
              <button variant="primary" onClick={() => setModalShow(true)} className="jss5"><GoLocation />{t('Select Delivery Location')}</button>
              <DeliveryLocation
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
            <div className="searchSec">
              <div className="searchInf">
                {/* <input type="text" /> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Search...')}
                  onClick={handleShow}
                  ref={inputRef}
                  onKeyDown={handleKeyDown}
                />
                <div className="rightSearchInfo">
                  <div className="allCategory">
                    {/* <select defaultValue="all category">
                      <option value="All Category">{t('All Category')}</option>
                      {catData && catData.map((item) => {
                        return <option value=""><Link to={`/product/category/${item?.title?._id}/${item?.title?.slug}`}>{item?.title?.name}</Link></option>
                      })}
                      <option value="Seeds">Seeds</option>
                      <option value="Crop Protection">
                        Crop Protection
                      </option>
                      <option value="Crop Nutrition">
                        Crop Nutrition
                      </option>
                      <option value="Machinery">Machinery</option>
                    </select> */}
                  </div>
                  <div className="searchIcon">
                    <BiSearch onClick={handleClick} />
                    {/* <button type="button" ></button> */}
                  </div>
                </div>
              </div>

              {show && (
                <div className="treandingSec" id="DropShow" ref={handleRef}>
                  <div className="trendingItem">
                    <h5 className="trendingTitle">Trending Seeds</h5>
                    <ul>
                      {trendSearch && trendSearch?.map((item, i) => {

                        return <li key={i}>
                          <Link to={`/products/${item.query}`} onClick={() => onSearch(item.query)}> {item.query}</Link>
                        </li>
                      })}
                    </ul>
                  </div>
                  <div className="trendingItem">
                    <h5 className="trendingTitle">Discover more</h5>
                    <ul>
                      <li>
                        <Link to="/">INSECTICIDES</Link>
                      </li>
                      <li>
                        <Link to="/">FUNGICIDES</Link>
                      </li>
                      <li>
                        <Link to="/">HERBICIDES</Link>
                      </li>
                      <li>
                        <Link to="/">BACTERICIDES</Link>
                      </li>
                      <li>
                        <Link to="/">ACARICIDES/MITICIDES</Link>
                      </li>
                    </ul>
                    <AiOutlineCloseCircle className="closeBtnDrop" onClick={handleShow} />
                  </div>
                </div>
              )}

            </div>

            <div className="category">
              <div className="cate">
                <BiCategory />
                {t('Categories')}
              </div>
              <div className="CATEGORYIE">
                <ul className="CategoryMenu_category_list">
                  {catData && catData?.map((item, i) => {
                    // console.log('top-cat', item?.title?.uid);
                    return <li className="category-menu" key={item?.title?.uid}>
                      <div className="category-menu-img">
                        <span className="span-image">
                          <Link to={`/product/category/${item?.title?.uid}/${item?.title?.slug}`}>
                            {
                              item?.url ? <img src={item?.url} alt="Product" /> : <img src={categories?.categData[i]?.imgUrl} alt="Product" />
                            }
                          </Link>
                        </span>
                        <Link to={`/product/category/${item?.title?.uid}/${item?.title?.slug}`}>
                          {item?.title?.name}
                        </Link>
                      </div>

                      <ul className="sub-menu-cat">
                        {item?.Submenu.map((item) => {
                          return <li key={item?._id}><Link to="#">{item?.name}</Link></li>
                        })}

                        {/* <li><a href="#">Boneless & Mince</a></li>
                        <li><a href="#">Gourmet Marinades</a></li> */}
                      </ul>
                    </li>
                  })}
                  {/* <li className="category-menu">
                    <div className="category-menu-img">
                      <span className="span-image">
                        <img src={cat1} alt="" />
                      </span>
                      Today's Deals
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>



            <div className="searchIcons">
              <div className="callDetail">
                <BiPhoneCall />
                <div className="callText">
                  <h6>{t('CALL US NOW')}</h6>
                  <p>{nums}</p>
                </div>
              </div>
              <div className="searchIconsItem">
                <ul>
                  <li>
                    <Link to="/myAccount">
                      <BsClockHistory />
                    </Link>
                  </li>
                  <li className="countWish">
                    <Link to="/wishlist">
                      <AiOutlineHeart />
                      {isLogin === 'true' && value?.length > 0 ? <span className="count">{value?.length}</span> : ''}
                      {/* {data?.cart.products > 0 && <span className="wishCircle"></span>} */}
                    </Link>
                  </li>
                  <li className="countParent">
                    <Link to="/cart">
                      <BsHandbag />
                      {/* {isLogin === 'true' ? <span className="count">{state?.cart?.products.length}</span> : <span className="count">{updatedProducts?.length}</span>} */}
                      {/* {isLogin === true ? data?.cart?.products?.length > 0 && <span className="count">{window.localStorage.getItem('cartLength')}</span> :
                        updatedProducts?.length > 0 ? <span className="count">{updatedProducts?.length}s</span> : ''} */}
                      {isLogin === 'false' ? cartLengh > 0 && <span className="count">{cartLengh}</span> :
                        updatedProducts?.length > 0 ? <span className="count">{cartLengh}</span> : ''}
                      {isLogin === 'true' ? cartLengh > 0 && <span className="count">{cartLengh}</span> :
                        updatedProducts?.length > 0 ? <span className="count">{cartLengh}</span> : ''}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
}

export default SearchBox;
