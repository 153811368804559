import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { base_url } from "../../server"
import { useTranslation } from "react-i18next";
import { BiCurrentLocation } from "react-icons/bi";

const API_endpont = `https://api.openweathermap.org/data/3.0/onecall?`
const API_key = 'fdc71785c551ce11ad586b9f7df5f0a8'

function DeliveryLocation(props) {
    const [modalShow, setModalShow] = useState(false);

    const [state, setState] = useState({
        insideIndia: true,
        outSideIndia: false
    })

    // const [withIndia, setWithIndia] = useState({
    //     india: ''
    // })

    // console.log(withIndia);

    const [country, setCountry] = useState(null)
    const [selectCountry, setSelectCountry] = useState(null)
    // console.log('kuhu', selectCountry)
    const [put, setPut] = useState(null)
    // console.log('put', put);

    const countrySelect = async () => {
        const res = await axios.get(`${base_url}country`, { withCredentials: true })
        setCountry(res.data)
        filterCounter(res.data)
    }
    const filterCounter = (data) => {
        // const clone = [...country]
        const filter = data.filter((item) => {
            return item._id !== "650ad979a4b4f2e26ffe8d86"
        })
        setCountry(filter)
    }

    const getData = async () => {
        const res = await fetch(`${base_url}settings/country`, {
            method: "GET",
            credentials: "include",
            // 'mode': 'cors',
            headers: {
                accept: "application/json, text/plain, /",
                "content-type": "application/json",
            },
        });
        setSelectCountry(res.data)
    }
    const putData = async (id) => {
        const res3 = await axios.put(`${base_url}settings/change/country`, { id: id }, { withCredentials: true });
        setPut(res3.data)
    }


    const [sendID, setSendId] = useState(null)
    const handleChange = (e) => {
        putData(e.target.value)
        setSendId(e.target.value)
        // setWithIndia(e.target.value)
        // console.log(e.target.value);
    }

    useEffect(() => {
        countrySelect()
        getData()
    }, [])

    const continueIndia = (india) => {

        if (india) {
            setPut(india)
            putData(india)

        } else {
            setPut(sendID)
            putData(sendID)
        }
        props.onHide()


    }



    const showCountry = (str) => {
        const clone = { ...state }
        if (str == 'ins') {
            clone.insideIndia = !state.insideIndia
            clone.outSideIndia = false
        } else {
            clone.outSideIndia = !state.outSideIndia
            clone.insideIndia = false
        }
        setState(clone)
    }

    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            console.log(position.coords);
            setLatitude(position.coords.latitude)
            setLongitude(position.coords.longitude)
        })
        let finalApiEndPoint = `${API_endpont}lat=${latitude}&lon=${longitude}&appid=${API_key}`
        console.log(finalApiEndPoint);
        // axios.get(finalApiEndPoint).then((res) => {
        //     console.log(res);
        // })
    }, [])

    const { t } = useTranslation()
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="dilivery-location"

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter text-center">
                        {t('Select Delivery Location')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{t('Select a delivery location to see product availability')}</h4>
                    <div className="india-side">
                        <div className="width-india">
                            <div className="form-check" >
                                <input className="form-check-input form-check-input-2" type="radio" onChange={() => showCountry('ins')} checked={state.insideIndia} name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    {t('Within India')}
                                </label>
                            </div>
                        </div>
                        {/* <div className="outsideIndia">
                            <div className="form-check">
                                <input className="form-check-input form-check-input-2 mr-3" type="radio" onChange={() => showCountry('out')} checked={state.outSideIndia} name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    {t('Outside India')}
                                </label>
                            </div>
                        </div> */}
                    </div>
                    {
                        state.insideIndia ? <div className="location-pup">
                            <div className="d-flex location-section">
                                <input type="text" className="search-loc" placeholder={t('* Enter Pincode, Location or Area')} />
                                <button type="button" className="get-loc"><BiCurrentLocation /> Use current Location</button>
                            </div>

                            <button className="conti" onClick={() => continueIndia('650ad979a4b4f2e26ffe8d86')}>{t('CONTINUE SHOPPING')}</button>
                        </div> : <div className="out-side-india">
                            <select className="form-select" aria-label="Default select example" onChange={handleChange}>
                                <option selected>{t('Select Country')}</option>
                                {country && country?.map((item) => {

                                    return <option key={item?._id} value={item._id}>{item?.name}</option>
                                })}
                            </select>

                            <input type="text" placeholder={t('Enter Delivery City')} />

                            <button className="conti"
                                onClick={() => continueIndia()}>{t('CONTINUUE SHOPPING')}</button>
                        </div>
                    }



                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>{t('Close')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default DeliveryLocation