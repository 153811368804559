import React, { useEffect, useState } from "react";
import { GrFacebookOption } from "react-icons/gr";
import { AiOutlineTwitter, AiOutlineInstagram, AiOutlineShoppingCart, AiOutlineUser } from "react-icons/ai";
import { TbBrandWhatsapp, TbJewishStarFilled } from "react-icons/tb";
import { FaBabyCarriage, FaShippingFast, FaUser } from "react-icons/fa";
import "./TopHeader.css";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { MdOutlineArrowDropDown, MdSell } from "react-icons/md";
import { BiLogInCircle, BiUser } from "react-icons/bi";
import { SiAdobecreativecloud } from "react-icons/si";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { useChangeCurrencyMutation, useChangeLanguageMutation, useGetCurrencyQuery, useGetLanguageQuery, useGetUserDetailQuery, usePostCartOfflineMutation } from "../../../components/products/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearUpdatedProduct } from "../../../components/products/productSlice";
import img from "../../../assets/img/client/2.jpg"
import { BsBuildingAdd } from "react-icons/bs";
import { CgTrack } from "react-icons/cg";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { GiNewspaper, GiWallet } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "../../../server";
import Timer from "../../../components/timer/Timer";

function TopHeader({ state, changeLange }) {
  const isLogin = window.localStorage.getItem("isLogin");
  const user_id = window.localStorage.getItem("user_id");
  const userName = window.localStorage.getItem("userName");
  const profileImg = window.localStorage.getItem("profilePic");
  const [user, setUser] = useState()

  // console.log(user.getaUser.lastName);

  const navigate = useNavigate();

  const [changeCurr, { isLoading: curLoad }] = useChangeCurrencyMutation()
  const [changeLang, { isLoading: langLoad }] = useChangeLanguageMutation()

  const { updatedProducts } = useSelector((state) => {
    return state.productList
  })


  // const { data: language } = useGetLanguageQuery()
  const [language, setlanguage] = useState(null)
  const [selected, setselected] = useState(null)
  const [langid, setLangid] = useState(null)
  const [put, setPut] = useState(null)
  const token = window.localStorage.getItem('token')
  const getDatas = async () => {
    const res = await axios.get(`${base_url}language`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      }
    })
    const res2 = await axios.get(`${base_url}settings/language`, {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      }
    })
    // const res2 = await fetch(`${base_url}settings/language`, {
    //   method: "GET",
    //   credentials: "include",
    //   // 'mode': 'cors',
    //   headers: {
    //     accept: "application/json, text/plain, /",
    //     "content-type": "application/json",
    //   },
    // });

    setlanguage(res.data)
    setselected(res2.data)





  }

  const putData = async (id) => {
    const res3 = await axios.put(`${base_url}settings/change/language`, { id: id }, { withCredentials: true });
    setPut(res3.data)
    window.location.reload()
  }

  useEffect(() => {
    getDatas()
    setLangid(window.localStorage.getItem('lang'))
  }, [])
  const { data: currency } = useGetCurrencyQuery()
  const dispatch = useDispatch()
  const logout = () => {
    window.localStorage.setItem("isLogin", false)
    window.localStorage.setItem("user_id", '')
    window.localStorage.setItem("userName", '')
    window.localStorage.setItem("token", '')
    window.localStorage.setItem("profilePic", false)
    dispatch(clearUpdatedProduct())
    navigate("/login");
  };
  const { data, isSuccess } = useGetUserDetailQuery(token)
  // console.log('data', data);


  useEffect(() => {
    setUser(data)
    // console.log(data);
    window.localStorage.setItem('userName', data?.getaUser?.firstname + " " + data?.getaUser?.lastname)
    window.localStorage.setItem('profilePic', data?.getaUser?.profilePhoto?.url)

  }, [data])

  // useEffect(() => {
  //   let currentLang = localStorage.getItem('engg')
  //   setLangid(currentLang)

  // }, [])


  const { t, i18n } = useTranslation()

  const onchengeHandle = (e) => {
    if (e.target.value == '65082c96fb799f1b73c8c6b7') {
      i18n.changeLanguage('ten')
      window.localStorage.setItem('preferredLanguage', 'ten');
      window.localStorage.setItem('lang', e.target.value);
      putData(e.target.value)
      setLangid(e.target.value)

    } else {
      i18n.changeLanguage('de')
      window.localStorage.setItem('preferredLanguage', 'de');
      window.localStorage.setItem('lang', e.target.value);
      putData(e.target.value)
      setLangid(e.target.value)
    }
    // if (e.target.id === 'languId') {
    //   const obj = { userid: user_id, language: e.target.value }
    //   setLangid(e.target.value)

    //   putData(e.target.value)
    //   const setLan = localStorage.setItem('engg', e.target.value)
    //   setLangid(setLan)

    // } else {
    //   const obj2 = { userid: user_id, currency: e.target.value }

    //   changeCurr(obj2)
    // }

  }
  // const COUNTDOWN_TARGET = new Date('2023-11-01T23:59:59')

  // const getTimeLeft = () => {
  //   const totalTimeLeft = COUNTDOWN_TARGET - new Date();
  //   const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
  //   const hour = Math.floor(totalTimeLeft / (1000 * 60 * 60) % 24);
  //   const minute = Math.floor(totalTimeLeft / (1000 * 60) % 60);
  //   const second = Math.floor(totalTimeLeft / (1000) % 60);
  //   return { days, hour, minute, second }
  // }

  // const [timeLeft, setTimeLeft] = useState(() => getTimeLeft())

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setTimeLeft(getTimeLeft())
  //   }, 1000)
  //   setLangid(window.localStorage.getItem('lang'))
  //   return () => {
  //     clearInterval(timer)
  //   }

  // }, [])




  return (
    <>
      <div className="topHeaderSec" id="topHead">
        <div className="container">
          <div className="topHeaderInfo">
            <div className="freeReturn">
              {/* <p>{state?.front_top_message}</p> */}
              <div className="timer timer-2">
                <Timer />
                {/* <ul>
                  {Object.entries(timeLeft).map((el) => {
                    const label = el[0]
                    const value = el[1]
                    return (
                      <li key={label}>
                        <span>{value}</span>
                        
                  {label}
                </li>
                )
                  })}
              </ul> */}
                <p>{t('Only today you can buy a Meatz with - 20% discount')}</p>
              </div>
            </div>

            {curLoad || langLoad && <div className="preloaderCount">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>}
            <div className="rightList">
              <div className="pageLinks">
                <ul>
                  {/* <li>
                    <Link to="/myAccount">My Account</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/about">About us </Link>
                  </li> */}
                  <li>
                    {/* <Link to="/blog">Blog</Link> */}
                  </li>
                  {/* {isLogin && <li>
                    <Link to="/wishlists">My Wishlist</Link>
                  </li>} */}
                  {/* <li>
                    <Link to="/cart">Cart</Link>
                  </li> */}



                </ul>
              </div>
              <div className="miscel">
                <div className="optionSelect">
                  <div className="lang">
                    {/* <button type="button" onClick={changeLange('en')}>English</button>
                    <button type="button" onClick={changeLange('de')}>Hindi</button> */}
                    <select defaultValue={"DEFAULT"} value={langid} name="language" id="languId" onChange={onchengeHandle}>
                      {/* <option value="DEFAULT">{data?.getaUser?.language ? data?.getaUser?.language?.name : 'ENG'}</option> */}
                      {language && language.map((item) => {
                        return <option key={item._id} value={item._id}>{item.name}</option>
                      })}
                    </select>
                  </div>
                  {/* <div className="rupees">
                    <button type="button" onClick={changeLange('en')}>English</button>
                    <button type="button" onClick={changeLange('de')}>Hindi</button>
                    <select defaultValue={"DEFAULT"} name="currency" id="currId" onChange={onchengeHandle} className="rupees-select">
                      <option value="DEFAULT"> {data?.getaUser?.currency?.name ? data?.getaUser?.currency?.name : 'INR'}</option>
                      {currency && currency.map((item) => {
                        if (item.name === data?.getaUser?.currency?.name) {
                          return
                        } else {
                          return <option key={item._id} value={item._id}>{item.name}</option>
                        }

                      })}
                    </select>
                  </div> */}
                </div>
                <div className="socialMedia">
                  <ul>
                    <li>
                      <a href="#">
                        <TbBrandWhatsapp />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <GrFacebookOption />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <AiOutlineTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <AiOutlineInstagram />
                      </a>
                    </li>
                    {isLogin === 'true' && (
                      <li>
                        <button
                          type="button"
                          className="logoutBtn"
                          onClick={logout}
                        >
                          {/* Logout */}
                          <AiOutlineUser />
                          {t('welcome_to_react')}
                        </button>
                      </li>
                    )}
                    {isLogin === 'false' && (
                      <li>
                        <Link to="/login">{t('welcome_to_reactt')}</Link>
                      </li>
                    )}
                    {isLogin === 'true' && <li style={{ background: "white", borderRadius: "100%", marginRight: "-10px", overflow: "hidden" }}>
                      {/* <AiOutlineUser /> */}
                      {profileImg === 'false' ? <AiOutlineUser style={{ width: "30px", height: "30px" }} /> : <img style={{ width: "30px", height: "30px" }} src={profileImg} />}

                    </li>}


                    {isLogin === 'true' && (
                      <li className="userNamePro">
                        <Link to="/" className="userNameText" >
                          {userName}  <MdOutlineArrowDropDown style={{ fontSize: "19px" }} />
                        </Link>
                        <ul className="dropdown-menu helll" >
                          <li><BiUser /> <Link to="/profile">My Profile</Link></li>
                          <li><AiOutlineShoppingCart /> <Link to="/myAccount">My Oders</Link></li>
                          <li><TbJewishStarFilled /> <Link to="/wishlist">My WishList</Link></li>
                          <li><FaBabyCarriage /> <Link to="/cart">My Cart</Link></li>
                          <li><GiWallet /> <Link to="/myWallet">My Wallet</Link></li>
                          <li><GiNewspaper /> <Link to="/my_product_reviews">My Product Reviews  </Link></li>
                          <li><VscActivateBreakpoints /> <Link to="/earning-points">My Earning Points</Link></li>
                          {/* <li><HiOutlineReceiptRefund /> <Link to="/sent-refund-request">Sent Refund Request</Link></li> */}
                          <li><BsBuildingAdd /> <Link to="/billingAddress">My Billing Address</Link></li>
                          <li><FaShippingFast /> <Link to="/shippingAddress">My Shipping Address</Link></li>
                          <li><MdSell /> <Link to="/allSeller">Visit Sellers</Link></li>
                          {/* <li><SiAdobecreativecloud /> <Link to="/seller/sign-Up">Become ETG Seller</Link></li> */}
                          <li><SiAdobecreativecloud /> <Link to="/rma_history">RMA History</Link></li>

                          <li><BiUser /> <Link to="/changePassword">Change Password</Link></li>
                          <li><CgTrack /> <Link to="/track-order">Track My Order</Link></li>
                          <li><MdSell /> <Link to="/support_ticket">Support Ticket</Link></li>
                          {isLogin === 'true' && <li onClick={logout}><BiLogInCircle /> Logout</li>}
                          {isLogin === 'false' && <li><BiLogInCircle /> <Link to="/login">Login </Link></li>}
                        </ul>
                      </li>
                    )}

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >


    </>
  );
}

export default TopHeader;
