

import info1 from '../../../assets/img/leatzmeat/food-quality1-1.fea6b364e267e3cb32ee.png'
import info2 from '../../../assets/img/leatzmeat/food-quality1-2.b6a200b83cd0e75c3c2c.png'
import info3 from '../../../assets/img/leatzmeat/food-quality1-3.61579f21661b984062a7.png'
function Brand() {
    return (
        <>
            <section className="information py-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                            <div className="food-quality">
                                <img src={info1} alt="" />
                            </div></div><div className="col-lg-4 col-md-4 col-sm-4 col-4"><div className="food-quality">
                                <img src={info2} alt="" />
                            </div></div><div className="col-lg-4 col-md-4 col-sm-4 col-4"><div className="food-quality">
                                <img src={info3} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Brand