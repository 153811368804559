import { useEffect } from "react";
import { useState } from "react";

function Timer() {
    const COUNTDOWN_TARGET = new Date('2023-12-01T23:59:59')

    const getTimeLeft = () => {
        const totalTimeLeft = COUNTDOWN_TARGET - new Date();
        const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
        const hour = Math.floor(totalTimeLeft / (1000 * 60 * 60) % 24);
        const minute = Math.floor(totalTimeLeft / (1000 * 60) % 60);
        const second = Math.floor(totalTimeLeft / (1000) % 60);
        return { days, hour, minute, second }
    }

    const [timeLeft, setTimeLeft] = useState(() => getTimeLeft())

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(getTimeLeft())
        }, 1000)

        return () => {
            clearInterval(timer)
        }

    }, [])
    return (
        <>
            <ul>
                {Object.entries(timeLeft).map((el) => {
                    const label = el[0]
                    const value = el[1]
                    return (
                        <li key={label}>
                            <span>{value}</span>
                            {/* <span className="lable">{label}</span> */}
                            {label}
                        </li>
                    )
                })}
            </ul>
            {/* <p>{t('Only today you can buy a Meatz with - 20% discount')}</p> */}
        </>
    )
}
export default Timer