import React, { useEffect } from "react";
import TopHeader from "./top-header/TopHeader";
import SearchBox from "./search-box/SearchBox";

import "./Header.css";
import Menus from "./menu/Menus";
import { useState } from "react";
import axios from "axios";

function Header({ changeLang }) {
  const [isSticky, setIsSticky] = useState(false);


  const fixedHeader = () => {
    if (window.scrollY >= 80) {
      setIsSticky(true)
    }
    else {
      setIsSticky(false);
    }
  }
  window.addEventListener("scroll", fixedHeader);


  const [state, setState] = useState({
    front_top_message: "",
    icon: "",
  })

  const getData = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/adminFrontMessage/id`)
    setState({
      front_top_message: res.data.front_top_message,
      icon: res.data.icon,
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <header className={`${isSticky ? "sticky active" : "sticky"}`}>
        <TopHeader state={state} changeLange={changeLang} />
        <SearchBox val={state} />
        {/* <section className="navbarArea">
          <div className="abaris-nav">
            <div className="container">
              <nav className="navbar navbarItem navbar-expand-md navbar-light">
                <Menus />
              </nav>
            </div>
          </div>
        </section> */}
      </header>
    </>
  );
}

export default Header;
